import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import _ from "lodash"

import LayoutEn from "../components/layout.en"
import HeaderEn from "../components/header.en"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

import * as privacyStyle from "./privcay.module.css"

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "privacy-banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <LayoutEn>
      <SEO title="隐私条例" />
      <div className={`mainContent`}>
        <HeaderEn
          activePage="privacy"
          color={{ bg: "transparent", text: "#000" }}
        />

        <div className="banner" data-bg-color="transparent" data-color="#000">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>

        <div
          className="privacy-content js-viewport-animation"
          data-bg-color="#f5f5f5"
          data-color="#000"
        >
          <div className="wp1180">
            <h1 className="title js-text-animation">Privacy Policy</h1>
            <p>
              <span class="js-text-animation">
                Effective date: December 09, 2019
              </span>
              <br />
              <span class="js-text-animation">
                S&W ("us", "we", or "our") operates the http://www.sunnwell.com/
                website (hereinafter referred to as the "Service").
              </span>
            </p>
            <p class="js-text-animation">
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. Our Privacy Policy
              for S&W is created with the help of the PrivacyPolicies.com
              Privacy Policy Generator.
            </p>
            <p class="js-text-animation">
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions, accessible from
              http://www.sunnwell.com/
            </p>
            <div className="part">
              <h2 class="js-text-animation">Information Collection And Use</h2>
              <p class="js-text-animation">
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <br />
              <br />
              <h3 class="js-text-animation">Types of Data Collected</h3>
              <h4 class="js-text-animation">Personal Data</h4>
              <p class="js-text-animation">
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
              </p>
              <ul>
                <li className="pl1">
                  <h5 class="js-text-animation">
                    •{"  "}Cookies and Usage Data
                  </h5>
                  <ul className="pl2">
                    <li>
                      <h6 class="js-text-animation">Usage Data</h6>
                      <p class="js-text-animation">
                        We may also collect information on how the Service is
                        accessed and used ("Usage Data"). This Usage Data may
                        include information such as your computer's Internet
                        Protocol address (e.g. IP address), browser type,
                        browser version, the pages of our Service that you
                        visit, the time and date of your visit, the time spent
                        on those pages, unique device identifiers and other
                        diagnostic data.
                      </p>
                    </li>
                    <li>
                      <h6 class="js-text-animation">Tracking & Cookies Data</h6>
                      <p class="js-text-animation">
                        We use cookies and similar tracking technologies to
                        track the activity on our Service and hold certain
                        information.
                        <br />
                        Cookies are files with small amount of data which may
                        include an anonymous unique identifier. Cookies are sent
                        to your browser from a website and stored on your
                        device. Tracking technologies also used are beacons,
                        tags, and scripts to collect and track information and
                        to improve and analyze our Service.
                        <br />
                        You can instruct your browser to refuse all cookies or
                        to indicate when a cookie is being sent. However, if you
                        do not accept cookies, you may not be able to use some
                        portions of our Service. You can learn more how to
                        manage cookies in the Browser Cookies Guide.
                      </p>
                    </li>
                    <li>
                      <h6 class="js-text-animation">
                        Examples of Cookies we use:
                      </h6>
                      <p class="js-text-animation pl3">
                        •{"  "}Session Cookies. We use Session Cookies to
                        operate our Service.
                        <br />•{"  "}Preference Cookies. We use Preference
                        Cookies to remember your preferences and various
                        settings.
                        <br />•{"  "}Security Cookies. We use Security Cookies
                        for security purposes.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="part">
              <h2 class="js-text-animation">Use of Data</h2>
              <h5 class="js-text-animation">
                S&W uses the collected data for various purposes:
              </h5>
              <div className="pl2">
                <p class="js-text-animation">
                  •{"  "}To provide and maintain the Service
                  <br />•{"  "}To notify you about changes to our Service
                  <br />•{"  "}To allow you to participate in interactive
                  features of our Service when you choose to do so
                  <br />•{"  "}To provide customer care and support
                  <br />•{"  "}To provide analysis or valuable information so
                  that we can improve the Service
                  <br />•{"  "}To monitor the usage of the Service
                  <br />•{"  "}To detect, prevent and address technical issues
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Transfer Of Data</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  Your information, including Personal Data, may be transferred
                  to — and maintained on — computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction. <br />
                  If you are located outside China and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to China and process it there. <br />
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer. <br />
                  S&W will take all steps reasonably necessary to ensure that
                  your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of your data
                  and other personal information.
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Disclosure Of Data</h2>
              <div className="pl2">
                <h5 class="js-text-animation">Legal Requirements</h5>
                <p class="js-text-animation">
                  S&W may disclose your Personal Data in the good faith belief
                  that such action is necessary to:
                </p>
                <p>
                  •{"  "}To comply with a legal obligation
                  <br />•{"  "}To protect and defend the rights or property of
                  S&W
                  <br />•{"  "}To prevent or investigate possible wrongdoing in
                  connection with the Service
                  <br />•{"  "}To protect the personal safety of users of the
                  Service or the public
                  <br />•{"  "}To protect against legal liability
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Security Of Data</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  The security of your data is important to us, but remember
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Service Providers</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  We may employ third party companies and individuals to
                  facilitate our Service ("Service Providers"), to provide the
                  Service on our behalf, to perform Service-related services or
                  to assist us in analyzing how our Service is used.
                  <br />
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>
                <p class="js-text-animation">Links To Other Sites</p>
                <p class="js-text-animation">
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site.
                  <br />
                  We strongly advise you to review the Privacy Policy of every
                  site you visit. We have no control over and assume no
                  responsibility for the content, privacy policies or practices
                  of any third party sites or services.
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Children's Privacy</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  Our Service does not address anyone under the age of 18
                  ("Children").
                </p>
                <p class="js-text-animation">
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 18. If you are a
                  parent or guardian and you are aware that your Children has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Changes To This Privacy Policy</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                  <br />
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the "effective date" at the top of this Privacy Policy.
                </p>
                <p class="js-text-animation">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
              </div>
            </div>

            <div className="part">
              <h2 class="js-text-animation">Contact Us</h2>
              <div className="pl2">
                <p class="js-text-animation">
                  If you have any questions about this Privacy Policy, please
                  contact us:
                  <br />•{"  "}By email:
                  <a class="js-text-animation" href="mailto:info@sunnwell.com">
                    info@sunnwell.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutEn>
  );
}

export default PrivacyPage
